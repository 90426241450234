<template>
  <div class="app-container">
    <div class="top_box">
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline account-top"
      >
        <el-form-item label="标题">
          <el-input
            v-model="queryForm.title"
            placeholder="群组标题..."
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="链接">
          <el-input
            v-model="queryForm.invite_link"
            placeholder="邀请链接..."
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="搜索">
          <el-input
            v-model="queryForm.search"
            placeholder="模糊检索..."
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <div class="content_t">
        <el-table
          v-loading="listLoading"
          :data="list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          class="tableList2"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          style="width: 100%"
          algin="center"
        >
          <el-table-column prop="avatar" label="头像" width="60">
            <template v-slot="scope">
              <img
                width="29px"
                height="29px"
                v-if="scope.row.avatar !== null"
                :src="scope.row.avatar"
                alt=""
              />
              <img
                width="29px"
                height="29px"
                v-else
                src="../../assets/imgs/mrtx.png"
                alt=""
              />
            </template>
          </el-table-column>

          <el-table-column
            v-for="(item, index) in headerList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
          ></el-table-column>
          <!-- <el-table-column label="操作">
            <template v-slot="scoped">
              <el-button @click="showDia(scoped.row.id)" type="text">
                标记
              </el-button>
              <el-button
                @click="$router.push(`/group/detail/${scoped.row.id}`)"
                type="text"
              >
                详情
              </el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
    <!-- 标签弹框 -->
    <el-dialog
      title="添加标签"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <LabelForm @reciverLabel="reciverLabel" v-if="dialogVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { getInviteLinkList } from '@/api/whatsApp'
import { getManagerStatus } from '@/utils/manager'
import LabelForm from '@/components/LabelForm.vue'
import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'searchGroupLink',
  mixins: [cacheMixin],
  components: {
    LabelForm
  },
  data() {
    return {
      list: null,
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      multipleSelection: [],
      queryForm: {
        page_index: 1,
        page_size: 10,
        search: '',
        id: ''
      },
      headerList: [
        // {
        //   label: 'ID',
        //   prop: 'id'
        // },
        {
          label: '群组名称',
          prop: 'title'
        },

        {
          label: '邀请链接',
          prop: 'invite_link'
        },
        {
          label: '创建时间',
          prop: 'create_time'
        }
      ],
      dialogVisible: false,
      userId: null
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.listLoading = true
      const res = await getInviteLinkList(this.queryForm)
      // console.log('res:', res)
      this.count = res.count
      this.list = res.items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.page_index = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.page_size = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.page_index = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    showDia(id) {
      this.dialogVisible = true
      this.userId = id
    },
    handleClose() {
      this.dialogVisible = false
    },
    // async sendResult(val) {
    //   const data = {
    //     ids: val.tags
    //   }
    //   await addTagForGroup(this.userId, data)
    //   this.dialogVisible = val.flag
    // },
    // 收到选中的标签
    reciverLabel(val) {
      // 收到值发送请求
      if (val.tags.length !== 0) {
        this.sendResult(val)
      } else {
        // 否则关闭弹框
        this.dialogVisible = val.flag
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  ::v-deep span.el-input__suffix {
    right: 20px;
  }
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 35px);
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
